import Link from "next/link";
import styled from "styled-components";

import Typography from "@/design-system/Typography";

import { theme } from "@/theme";

const CoursesBoxStyle = styled.div`
  padding: 24px 16px;
  margin-top: 24px;
  background-color: inherit;
`;

const CourseLinkStyle = styled.div`
  padding: 16px 8px;
  border-bottom: 1px solid ${theme.colors.primary[100]};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CoursesLink = ({ setIsNavMobileOpen, isRow }: any) => {
  return (
    <CoursesBoxStyle>
      <Typography varient="labels" ml={"8px"}>
        {isRow ? "PROGRAMS" : "COURSES"}
      </Typography>

      <CourseLinkStyle>
        <Link
          href={"/courses/building-information-modelling"}
          onClick={() => setIsNavMobileOpen(false)}
        >
          <Typography varient="subheadingl" weightVarient="medium" color={theme.colors.gray[800]}>
            {isRow
              ? "PGP in Building Information Modelling (BIM) for Architects"
              : "BIM for Architects"}
          </Typography>
        </Link>
      </CourseLinkStyle>
      <CourseLinkStyle>
        <Link href={"/courses/computational-design"} onClick={() => setIsNavMobileOpen(false)}>
          <Typography varient="subheadingl" weightVarient="medium" color={theme.colors.gray[800]}>
            {isRow ? "PGP in Parametric Design for Architects" : "Master Computational Design"}
          </Typography>
        </Link>
      </CourseLinkStyle>
      <CourseLinkStyle>
        <Link href={"/courses/bim-for-civil-engineers"} onClick={() => setIsNavMobileOpen(false)}>
          <Typography varient="subheadingl" weightVarient="medium" color={theme.colors.gray[800]}>
            {isRow
              ? "PGP in Building Information Modelling (BIM) for Civil Engineers"
              : "BIM for Civil engineers"}
          </Typography>
        </Link>

        {/* <Badge
          text={"Coming Soon"}
          badgeStyle={"light"}
          colorVarient={"warning"}
          dotLeading={true}
          size={"md"}
        /> */}
      </CourseLinkStyle>
    </CoursesBoxStyle>
  );
};

export default CoursesLink;
