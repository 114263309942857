import Link from "next/link";
import styled from "styled-components";

import Typography from "@/design-system/Typography";

import { theme } from "@/theme";

const MenuStyle = styled.div`
  padding: ${theme.spacing(6)} ${theme.spacing(4)};
  background-color: inherit;
`;

const MenuLinkStyle = styled.div`
  padding: ${theme.spacing(2)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MenuLink = ({ setIsNavMobileOpen, isRow }: any) => {
  return (
    <MenuStyle>
      <MenuLinkStyle>
        <Link
          href={"/hire-from-us"}
          onClick={() => {
            setIsNavMobileOpen(false);
          }}
        >
          <Typography varient="subheadings" weightVarient="medium">
            Hire From Us
          </Typography>
        </Link>
      </MenuLinkStyle>

      <MenuLinkStyle>
        <Link className="link__" href={"/apply-as-mentor"}>
          <Typography varient="subheadings" weightVarient="medium">
            Become a Mentor
          </Typography>
        </Link>
      </MenuLinkStyle>

      <MenuLinkStyle>
        <Link
          href={"/about"}
          onClick={() => {
            setIsNavMobileOpen(false);
          }}
        >
          <Typography varient="subheadings" weightVarient="medium">
            About Us
          </Typography>
        </Link>
      </MenuLinkStyle>
      <MenuLinkStyle>
        <Link
          href={"/learning-hub"}
          onClick={() => {
            setIsNavMobileOpen(false);
          }}
        >
          <Typography varient="subheadings" weightVarient="medium">
            Blogs
          </Typography>
        </Link>
      </MenuLinkStyle>
      <MenuLinkStyle>
        <Link
          href={"/career"}
          onClick={() => {
            setIsNavMobileOpen(false);
          }}
        >
          <Typography varient="subheadings" weightVarient="medium">
            Careers
          </Typography>
        </Link>
      </MenuLinkStyle>

      <MenuLinkStyle>
        <Link
          href={"/webinars"}
          onClick={() => {
            setIsNavMobileOpen(false);
          }}
        >
          <Typography varient="subheadings" weightVarient="medium">
            Events
          </Typography>
        </Link>
      </MenuLinkStyle>

      <MenuLinkStyle>
        <Link
          href={"/impact-on-aec"}
          onClick={() => {
            setIsNavMobileOpen(false);
          }}
        >
          <Typography varient="subheadings" weightVarient="medium">
            Our Impact
          </Typography>
        </Link>
      </MenuLinkStyle>
      {!isRow && (
        <MenuLinkStyle>
          <Link
            href={"/career-quiz/onboarding"}
            onClick={() => {
              setIsNavMobileOpen(false);
            }}
          >
            <Typography varient="subheadings" weightVarient="medium">
              Career Counselling
            </Typography>
          </Link>
        </MenuLinkStyle>
      )}
    </MenuStyle>
  );
};

export default MenuLink;
